(function ($, w, undefined) {
    function VideoEmbed() {
        this.ELEMENT_CONTAINER = 'data-video-embed';
        this.ELEMENT_ID = 'data-video-embed-id';
        this.ELEMENT_CLOSE = 'data-video-embed-close';
        this.ELEMENT_PLAY = 'data-video-embed-play';
        this.VIDEO_PLAYER = '.video-player';


        this.loadYouTube = false;
        this.loadVimeo = false;
        this.elements = {};


        this.detectElements();
    }

    VideoEmbed.prototype.get = function (name) {

    };

    VideoEmbed.prototype.detectElements = function () {
        var self = this;
        $('[' + this.ELEMENT_CONTAINER + ']').each(function () {
            var elem = $(this);
            var id = elem.attr(self.ELEMENT_ID);
            var type = elem.attr(self.ELEMENT_CONTAINER);
            self.elements[id] = {
                id: id,
                type: type,
                player: null,
                elem: elem
            };
            $(self.VIDEO_PLAYER, elem).attr('id', self.getElementId(self.elements[id]));

            if (type === 'youtube') {
                self.loadYouTube = true;
            }
            else if (type === 'vimeo') {
                self.loadVimeo = true;
            }
        });

        this.loadDependencies();
        this.setEvents();
    };

    VideoEmbed.prototype.loadDependencies = function () {
        var self = this;
        var tag;
        var firstScriptTag;
        if (this.loadYouTube) {
            tag = document.createElement('script');
            tag.src = "//www.youtube.com/iframe_api";
            firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            if (w.YT && w.YT.loaded) {
                this.initYoutubePlayers();
            }
            else {
                w.onYouTubeIframeAPIReady = function () {
                    self.initYoutubePlayers();
                }
            }
        }

        if (this.loadVimeo) {
            tag = document.createElement('script');
            tag.src = "//player.vimeo.com/api/player.js";
            firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            this.initVimeo();
        }
    };

    /**
     * Checks to see if vimeo has loaded and when it does then initialise it.
     */
    VideoEmbed.prototype.initVimeo = function () {
        if (w.Vimeo) {
            for (var element in this.elements) {
                if (this.elements.hasOwnProperty(element)) {
                    if (this.elements[element].type === 'vimeo') {
                        var options = {
                            id: this.elements[element].id,
                            loop: false
                        };
                        this.elements[element].player = new Vimeo.Player(this.getElementId(this.elements[element]), options);
                    }
                }
            }
        }
        else {
            setTimeout(this.initVimeo.bind(this), 120);
        }
    };

    VideoEmbed.prototype.initYoutubePlayers = function () {
        for (var element in this.elements) {
            if (this.elements.hasOwnProperty(element)) {
                if (this.elements[element].type === 'youtube') {
                    this.elements[element].player = new YT.Player(this.getElementId(this.elements[element]), {
                        videoId: this.elements[element].id,
                        playerVars: {
                            'autoplay': 0,
                            'rel': 0,
                            'showinfo': 0
                        }
                    });
                }
            }
        }
    };

    VideoEmbed.prototype.setEvents = function () {
        var self = this;
        $('[' + this.ELEMENT_PLAY + ']').each(function () {
            var elem = $(this);
            elem.unbind('click').click(self.eventPlay.bind(self));
        });

        $('[' + this.ELEMENT_CLOSE + ']').each(function () {
            var elem = $(this);

            elem.unbind('click').click(self.eventClose.bind(self));
        });
    };

    VideoEmbed.prototype.eventPlay = function (e) {
        e.preventDefault();
        var id = $(e.currentTarget).attr(this.ELEMENT_PLAY);
        this.play(id);
    };

    VideoEmbed.prototype.eventClose = function (e) {
        e.preventDefault();

        var id = $(e.currentTarget).attr(this.ELEMENT_CLOSE);
        this.close(id);
    };

    VideoEmbed.prototype.getElementId = function (element) {
        return element.type + '-' + element.id;
    };

    VideoEmbed.prototype.play = function (id) {
        if (this.elements[id]) {
            var element = this.elements[id];
            if (element.type === 'youtube') {
                if (element.player && element.player.playVideo) {
                    element.elem.show();
                    element.player.playVideo();
                }
            }
            else if (element.type === 'vimeo') {
                if (element.player && element.player.play) {
                    element.elem.show();
                    element.player.play();
                }
            }
        }
    };

    VideoEmbed.prototype.stop = function (id) {
        if (this.elements[id]) {
            var element = this.elements[id];
            if (element.type === 'youtube') {
                if (element.player && element.player.stopVideo) {
                    element.player.stopVideo();
                }
            }
            else if (element.type === 'vimeo') {
                if (element.player && element.player.pause) {
                    element.player.pause();
                    element.player.setCurrentTime(0);
                }
            }
        }
    };

    VideoEmbed.prototype.close = function (id) {
        this.stop(id);
        if (this.elements[id]) {
            var element = this.elements[id];
            element.elem.hide();
        }
    };

    w.THF = w.THF || {};
    w.THF.Interface = w.THF.Interface || {};
    w.THF.Interface.VideoEmbed = new VideoEmbed();
})(jQuery, window);